import React, { useEffect, useState } from 'react';
import styles from './Chat.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { customErrorSnack, getChat, sendPublicMessage } from '../../app/appActions';
import { ButtonMUI } from '../../shared';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { ReactComponent as Reply } from '../../assets/icons/reply.svg';
import moment from 'moment';
import { FraudFieldLabel } from '../Auth/helperAuthFunc';

const Chat = () => {
  const dispatch = useDispatch();
  const { chat } = useSelector(({ app }) => app)
  const [tab, setTab] = useState(0);
  const [messageValue, setMessageValue] = useState({ text: '' });

  useEffect(() => {
    dispatch(getChat(!tab))
  }, [tab])

  const loadMore = () => {
    if (!!chat.next && chat.next.includes('?cursor=')) {
      dispatch(getChat(!tab, `?cursor=${chat.next.split('?cursor=')[1]}`))
    }
  }

  const sendMessage = () => {
    if (messageValue.text.length < 1) {
      dispatch(customErrorSnack({ error: { response: { data: ['Your message can not be blank'] } } }))
    } else if (messageValue.text.length > 500) {
      dispatch(customErrorSnack({ error: { response: { data: ['Your message cannot be more then 500 characters'] } } }))
    } else {
      dispatch(sendPublicMessage(messageValue)).then(res => {
        if (res.type.includes('_SUCCESS')) {
          dispatch(getChat(!tab))
          setMessageValue({ text: '' })
        } else {
          dispatch(customErrorSnack(res))
        }
      })
    }
  }

  const renderChatItem = (el, isReply) => {
    return <div key={el.id} className={`${styles.chatList__item}${isReply ? ` ${styles.reply}` : ''}`}>
      <div className={styles.heading}>
        {isReply && <Reply />}
        <span className={styles.name}>{isReply ? 'FraudCue Support' : el.author.full_name}</span>
        <Clock />
        <span className={styles.date}>{moment(el.created_at).format('DD.MM.YYYY')}</span>
      </div>
      <div className={styles.body}>
        {el.text}
      </div>
      {!!el.admin_message && renderChatItem(el.admin_message, true)}
    </div>
  }

  return (
    <main className={`${styles.chat} page-wrap`}>
      <div className={`${styles.chatInner} container`}>
        <div className="page-heading">
          <h1>Join the Conversation</h1>
          <p>Stay informed and protect yourself from scammers by joining our forum community where we discuss tips, share stories, and raise awareness about the latest scams.</p>
        </div>
        <section className={styles.chatWrap}>
          <div className={`tabs ${!!tab}`}>
            {tabs.map((el, idx) => <button
              key={idx}
              className={`${tab === idx}${tab === idx ? ` active` : ''}`}
              onClick={() => setTab(idx)}
            >
              {el.title}
            </button>)}
          </div>
          {!tab && <div className={styles.sendMessage}>
            <FraudFieldLabel
              title={'Your message'}
            >
              <input
                placeholder='Type here...'
                value={messageValue.text}
                onChange={({ target: { value } }) => setMessageValue({ text: value })}
              />
            </FraudFieldLabel>
            <ButtonMUI
              variant='contained'
              className={styles.searchBtn}
              onClick={sendMessage}
            >
              Submit
            </ButtonMUI>
          </div>}
          <div className={styles.chatList}>
            {chat.results.length > 0
              ? chat.results.map(el => renderChatItem(el))
              : !!tab
                ? <span className={styles.noMessages}>No support replies</span>
                : <span className={styles.noMessages}>No general messages</span>}
          </div>
          {!!chat.next &&
            <ButtonMUI
              variant='contained'
              className={styles.searchBtn}
              onClick={loadMore}
            >
              Load more
            </ButtonMUI>}
        </section>
      </div>
    </main>
  );
};

const tabs = [
  {
    title: 'General chat'
  },
  {
    title: 'Support replies'
  }
]

export default Chat;
