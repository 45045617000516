import { ButtonMUI } from '../../shared'
import styles from './Auth.module.scss'
import extraStyles from '../../layout/Header/Header.module.scss'
import { useDispatch } from 'react-redux'
import { deleteProfile, setAuthModal } from '../../app/appActions'

const DeleteAccount = () => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(deleteProfile())
    }

    return <div className={styles.modalWrapper}>
        <p className={styles.desc}>Are you sure you want to delete your account?<br />This action cannot be undone.</p>
        <div className={styles.buttons}>
            <ButtonMUI
                variant='contained'
                className={[extraStyles.actionBtn, styles.actionBtn, extraStyles.signUp].join(' ')}
                onClick={() => dispatch(setAuthModal(false))}
            >
                No, cancel
            </ButtonMUI>
            <ButtonMUI
                variant='outlined'
                className={[extraStyles.actionBtn, styles.actionBtn, extraStyles.signIn].join(' ')}
                onClick={handleDelete}
            >
                Yes, delete
            </ButtonMUI>
        </div>
    </div>
}

export default DeleteAccount