import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { mainPath } from './paths';
import MainContainer from '../containers/MainContainer';

const AppRoutes = () => {
  return (
    <Switch>
      <Route
        path='/'
        exact
        render={() => <Redirect to={mainPath.home.path} />}
      />
      {/*<Route path={rootAuthPath} component={AuthContainer} /> */}
      <Route component={MainContainer} />
    </Switch>
  );
};

export default AppRoutes;
