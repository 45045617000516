import { APP } from './appActionTypes';

export const getProfileInfo = () => ({
  type: APP.GET_PROFILE_INFO,
  payload: {
    client: 'default',
    request: {
      url: `auth/profile`,
      method: 'GET',
    },
  },
});

export const deleteProfile = () => ({
  type: APP.DELETE_PROFILE,
  payload: {
    client: 'default',
    request: {
      url: `auth/account`,
      method: 'DELETE',
    },
  },
});

export const postLogIn = data => ({
  type: APP.POST_LOGIN,
  payload: {
    client: 'default',
    request: {
      url: `auth/sign-in/`,
      method: 'POST',
      data
    },
  },
});

export const passwordRecovery = data => ({
  type: APP.PASSWORD_RECOVERY,
  payload: {
    client: 'default',
    request: {
      url: `auth/recovery/sms/send/`,
      method: 'POST',
      data
    },
  },
});

export const setNewPassword = (data, password_token) => ({
  type: APP.SET_NEW_PASSWORD,
  payload: {
    client: 'default',
    request: {
      url: `auth/recovery/sms/set-password/${password_token}/`,
      method: 'POST',
      data
    },
  },
});

export const postRegistration = data => ({
  type: APP.POST_REGISTRATION,
  payload: {
    client: 'default',
    request: {
      url: `auth/sign-up/`,
      method: 'POST',
      data
    },
  },
});

export const postRegVerification = (smsToken, code) => ({
  type: APP.POST_REG_VERIFICATION,
  payload: {
    client: 'default',
    request: {
      url: `auth/sign-up/sms/confirm/${smsToken}/${code}/`,
      method: 'POST'
    },
  },
});

export const postRecoveryVerification = (smsToken, code) => ({
  type: APP.POST_RECOVERY_VERIFICATION,
  payload: {
    client: 'default',
    request: {
      url: `auth/recovery/sms/verify/${smsToken}/${code}/`,
      method: 'POST'
    },
  },
});

export const resendRegVerCode = (smsToken) => ({
  type: APP.RESEND_REG_VER_CODE,
  payload: {
    client: 'default',
    request: {
      url: `auth/sign-up/sms/resend/${smsToken}/`,
      method: 'POST',
    },
  },
});

export const resendRecoveryVerCode = (smsToken) => ({
  type: APP.RESEND_RECOVERY_VER_CODE,
  payload: {
    client: 'default',
    request: {
      url: `auth/recovery/sms/resend/${smsToken}/`,
      method: 'POST',
    },
  },
});

export const checkAction = (type, data) => ({
  type: APP.CHECK,
  payload: {
    client: 'default',
    request: {
      url: `main/check-${!!type ? 'phonenumber' : 'upi'}`,
      method: 'POST',
      data
    },
  },
});

export const sendPublicMessage = (data) => ({
  type: APP.POST_CHAT,
  payload: {
    client: 'default',
    request: {
      url: `chat/public-messanges`,
      method: 'POST',
      data
    },
  },
});

export const getChat = (type, cursor = '') => ({
  type: APP.GET_CHAT,
  payload: {
    client: 'default',
    isPagination: cursor.length > 0,
    request: {
      url: `chat/${type ? 'public' : 'private'}-messanges${cursor}`,
      method: 'GET'
    },
  },
});

export function closeErrorSnack() {
  return {
    type: APP.ERROR_SNACK_CLOSE,
  };
}

export function customEmptyErrorSnack(fieldName) {
  return {
    type: APP.ERROR_SNACK_OPEN,
    payload: `Field "${fieldName}" may not be blank`
  };
}

export function customErrorSnack(e) {
  let errorText
  if (Array.isArray(e.error.response.data)) {
    errorText = e.error.response.data[0]
  } else if (typeof e.error.response.data === 'object') {
    errorText = Object.values(e.error.response.data)[0][0]
  }
  return {
    type: APP.ERROR_SNACK_OPEN,
    payload: errorText
  };
}

export const setAuthModal = (status) => ({
  type: APP.SET_AUTH_MODAL,
  payload: status,
});