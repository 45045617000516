import React, { useEffect, useState } from 'react';
import { FraudFieldLabel, PasswordField } from './helperAuthFunc';
import { ButtonMUI, MaskInput } from '../../shared';
import styles from './Auth.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { customEmptyErrorSnack, customErrorSnack, getProfileInfo, postLogIn, setAuthModal } from '../../app/appActions';
import { formKeysNames } from '../../helpers/functions';

const initialFormValues = {
    phone_number: '',
    password: ''
}

const SignIn = () => {
    const dispatch = useDispatch();
    const { authModalStatus } = useSelector(({ app }) => app)
    const [signInValues, setSignInValues] = useState(initialFormValues)
    const [btnLoad, setBtnLoad] = useState(false)

    useEffect(() => {
        setSignInValues(initialFormValues)
    }, [authModalStatus])

    const signInConfirm = () => {
        if (Object.values(signInValues).some(el => el === '+910000000000' || el === '')) {
            const [emptyKey] = Object.entries(signInValues).find(([key, value]) => value === '+910000000000' || value === '')
            dispatch(customEmptyErrorSnack(formKeysNames[emptyKey]))
        } else {
            setBtnLoad(true)
            dispatch(postLogIn(signInValues)).then((res) => {
                if (res.type.includes('_FAIL')) dispatch(customErrorSnack(res))
                if (res.type.includes('_SUCCESS')) dispatch(getProfileInfo())
                setBtnLoad(false)
            })
        }
    }

    return <div className={styles.modalWrapper}>
        <FraudFieldLabel
            title={'Phone number'}
        >
            <MaskInput
                className={styles.phoneInput}
                getter={signInValues.phone_number}
                setter={({ target: { value } }) => setSignInValues(prev => ({ ...prev, phone_number: value }))}
            />
        </FraudFieldLabel>

        <PasswordField
            title={'Password'}
            placeholder={'Enter your password...'}
            withRecoverBtn
            getter={signInValues.password}
            setter={({ target: { value } }) => setSignInValues(prev => ({ ...prev, password: value }))}
        />

        <ButtonMUI
            variant='contained'
            className={styles.submitBtn}
            onClick={signInConfirm}
            disabled={btnLoad}
        >
            Sign in
        </ButtonMUI>
        <p className={styles.info}>Don’t have an account? <button type='button' onClick={() => dispatch(setAuthModal('signUp'))} className='fraud-text'>Sign up</button></p>
    </div>
};

export default SignIn