export const APP = {
  LOADING: 'LOADING',
  BUTTON_LOADING: 'BUTTON_LOADING',
  SET_AUTH_MODAL: 'SET_AUTH_MODAL',

  ERROR_SNACK_OPEN: 'ERROR_SNACK_OPEN',
  ERROR_SNACK_CLOSE: 'ERROR_SNACK_CLOSE',

  SUCCESS_SNACK_OPEN: 'SUCCESS_SNACK_OPEN',
  SUCCESS_SNACK_CLOSE: 'SUCCESS_SNACK_CLOSE',

  GET_PROFILE_INFO: 'GET_PROFILE_INFO',
  GET_PROFILE_INFO_SUCCESS: 'GET_PROFILE_INFO_SUCCESS',
  GET_PROFILE_INFO_FAIL: 'GET_PROFILE_INFO_FAIL',

  DELETE_PROFILE: 'DELETE_PROFILE',
  DELETE_PROFILE_SUCCESS: 'DELETE_PROFILE_SUCCESS',
  DELETE_PROFILE_FAIL: 'DELETE_PROFILE_FAIL',

  POST_LOGIN: 'POST_LOGIN',
  POST_LOGIN_SUCCESS: 'POST_LOGIN_SUCCESS',
  POST_LOGIN_FAIL: 'POST_LOGIN_FAIL',

  PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
  PASSWORD_RECOVERY_SUCCESS: 'PASSWORD_RECOVERY_SUCCESS',
  PASSWORD_RECOVERY_FAIL: 'PASSWORD_RECOVERY_FAIL',

  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  SET_NEW_PASSWORD_SUCCESS: 'SET_NEW_PASSWORD_SUCCESS',
  SET_NEW_PASSWORD_FAIL: 'SET_NEW_PASSWORD_FAIL',

  POST_REGISTRATION: 'POST_REGISTRATION',
  POST_REGISTRATION_SUCCESS: 'POST_REGISTRATION_SUCCESS',
  POST_REGISTRATION_FAIL: 'POST_REGISTRATION_FAIL',

  POST_REG_VERIFICATION: 'POST_REG_VERIFICATION',
  POST_REG_VERIFICATION_SUCCESS: 'POST_REG_VERIFICATION_SUCCESS',
  POST_REG_VERIFICATION_FAIL: 'POST_REG_VERIFICATION_FAIL',

  POST_RECOVERY_VERIFICATION: 'POST_RECOVERY_VERIFICATION',
  POST_RECOVERY_VERIFICATION_SUCCESS: 'POST_RECOVERY_VERIFICATION_SUCCESS',
  POST_RECOVERY_VERIFICATION_FAIL: 'POST_RECOVERY_VERIFICATION_FAIL',

  RESEND_REG_VER_CODE: 'RESEND_REG_VER_CODE',
  RESEND_REG_VER_CODE_SUCCESS: 'RESEND_REG_VER_CODE_SUCCESS',
  RESEND_REG_VER_CODE_FAIL: 'RESEND_REG_VER_CODE_FAIL',

  RESEND_RECOVERY_VER_CODE: 'RESEND_RECOVERY_VER_CODE',
  RESEND_RECOVERY_VER_CODE_SUCCESS: 'RESEND_RECOVERY_VER_CODE_SUCCESS',
  RESEND_RECOVERY_VER_CODE_FAIL: 'RESEND_RECOVERY_VER_CODE_FAIL',

  CHECK: 'CHECK',
  CHECK_SUCCESS: 'CHECK_SUCCESS',
  CHECK_FAIL: 'CHECK_FAIL',

  GET_CHAT: 'GET_CHAT',
  GET_CHAT_SUCCESS: 'GET_CHAT_SUCCESS',
  GET_CHAT_FAIL: 'GET_CHAT_FAIL',

  POST_CHAT: 'POST_CHAT',
  POST_CHAT_SUCCESS: 'POST_CHAT_SUCCESS',
  POST_CHAT_FAIL: 'POST_CHAT_FAIL',
};
