import React, {useEffect, useState} from 'react';
import {FraudFieldLabel, PasswordField} from './helperAuthFunc';
import {ButtonMUI, MaskInput} from '../../shared';
import styles from './Auth.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {customEmptyErrorSnack, customErrorSnack, postRegistration, setAuthModal} from '../../app/appActions';
import {formKeysNames} from '../../helpers/functions';

const initialFormValues = {
  full_name: '',
  phone_number: '',
  // email: '',
  city: '',
  password: ''
};

const SignUp = () => {
  const dispatch = useDispatch();
  const {authModalStatus} = useSelector(({app}) => app);
  const [signUpValues, setSignUpValues] = useState(initialFormValues);
  const [btnLoad, setBtnLoad] = useState(false);

  useEffect(() => {
    setSignUpValues(initialFormValues);
  }, [authModalStatus]);

  const signUpConfirm = () => {
    if (Object.values(signUpValues).some((el) => el === '+910000000000' || el === '')) {
      const [emptyKey] = Object.entries(signUpValues).find(([key, value]) => value === '+910000000000' || value === '');
      dispatch(customEmptyErrorSnack(formKeysNames[emptyKey]));
    } else {
      setBtnLoad(true);
      dispatch(postRegistration(signUpValues)).then((res) => {
        if (res.type.includes('_FAIL')) dispatch(customErrorSnack(res));
        setBtnLoad(false);
      });
    }
  };

  return (
    <div className={styles.modalWrapper}>
      <FraudFieldLabel title={'Full name'}>
        <input
          placeholder='Enter your name...'
          value={signUpValues.full_name}
          onChange={({target: {value}}) => setSignUpValues((prev) => ({...prev, full_name: value}))}
        />
      </FraudFieldLabel>

      <FraudFieldLabel title={'Phone number'}>
        <MaskInput
          className={styles.phoneInput}
          getter={signUpValues.phone_number}
          setter={({target: {value}}) => setSignUpValues((prev) => ({...prev, phone_number: value}))}
        />
      </FraudFieldLabel>

      {/* <FraudFieldLabel
      title={'Email'}
    >
      <input
        placeholder='Enter your email...'
        type='email'
        value={signUpValues.email}
        onChange={({ target: { value } }) => setSignUpValues(prev => ({ ...prev, email: value }))}
        autoComplete='off'
      />
    </FraudFieldLabel> */}

      <FraudFieldLabel title={'City'}>
        <input
          placeholder='Enter your city...'
          value={signUpValues.city}
          onChange={({target: {value}}) => setSignUpValues((prev) => ({...prev, city: value}))}
        />
      </FraudFieldLabel>

      <PasswordField
        title={'Password'}
        placeholder={'Enter your password...'}
        getter={signUpValues.password}
        setter={({target: {value}}) => setSignUpValues((prev) => ({...prev, password: value}))}
      />

      <ButtonMUI variant='contained' className={styles.submitBtn} onClick={signUpConfirm} disabled={btnLoad}>
        Sign up
      </ButtonMUI>
      <p className={styles.info}>
        Already have an account?{' '}
        <button type='button' onClick={() => dispatch(setAuthModal('signIn'))} className='fraud-text'>
          Sign in
        </button>
      </p>
    </div>
  );
};

export default SignUp;
