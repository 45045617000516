import React, { useEffect, useState } from 'react';
import ReactCodeInput from 'react-verification-code-input';
import { ButtonMUI, MaskInput } from '../../shared';
import styles from './Auth.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { customErrorSnack, getProfileInfo, postRecoveryVerification, postRegVerification, resendRecoveryVerCode, resendRegVerCode, setAuthModal } from '../../app/appActions';

const Verify = () => {
    const dispatch = useDispatch();
    const { authModalStatus, sms_token, tempPhone } = useSelector(({ app }) => app)
    const [verNum, setVerNum] = useState('')
    const [btnLoad, setBtnLoad] = useState(false)

    const sendAgain = () => {
        dispatch(authModalStatus === 'recoveryVerify' ? resendRecoveryVerCode(sms_token) : resendRegVerCode(sms_token))
    }

    const VerNumAction = (e, actionType) => {
        if (actionType === 'change') {
            setVerNum(e)
        } else if (actionType === 'complete') {
            //console.log(actionType, e)
        }
    }

    const confirmVerify = () => {
        setBtnLoad(true)
        dispatch(authModalStatus === 'recoveryVerify' ? postRecoveryVerification(sms_token, verNum) : postRegVerification(sms_token, verNum)).then((res) => {
            if (res.type.includes('_FAIL')) dispatch(customErrorSnack(res))
            if (res.type.includes('_SUCCESS') && authModalStatus === 'verify') dispatch(getProfileInfo())
            setBtnLoad(false)
        })
    }

    const displayTempPhone = () => {
        let phone = tempPhone.split('')

        phone.splice(3, 0, ' ')
        phone.splice(6, 0, ' ')
        phone.splice(11, 0, ' ')

        return <span>{phone.join('')}</span>
    }

    return <div className={styles.modalWrapper}>

        <p className={styles.desc}>
            Enter the code we’ve sent you by SMS to your<br />phone number {displayTempPhone()}
        </p>

        <ReactCodeInput
            type='number'
            fields={4}
            onChange={(e) => VerNumAction(e, 'change')}
            onComplete={(e) => VerNumAction(e, 'complete')}
            className={styles.verifyInput}
        />

        <ButtonMUI
            variant='contained'
            className={styles.submitBtn}
            onClick={confirmVerify}
            disabled={btnLoad}
        >
            Sign in
        </ButtonMUI>
        <p className={styles.info}>Didn’t get a code? <button type='button' onClick={sendAgain} className='fraud-text'>Send again</button></p>
    </div>
};

export default Verify