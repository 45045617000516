import React, { Fragment } from 'react';
import Header from '../../layout/Header';
import Footer from '../../layout/Footer';
import MainRoutes from '../../routes/MainRoutes';
import { DialogMUI } from '../../shared';
import { useDispatch, useSelector } from 'react-redux';
import { setAuthModal } from '../../app/appActions';
import { Recovery, SignIn, SignUp, Verify } from '../../features/Auth'
import styles from './MainContainer.module.scss';
import DeleteAccount from '../../features/Auth/Delete';

const MainContainer = () => {
  const dispatch = useDispatch();
  const { authModalStatus } = useSelector(({ app }) => app)
  return (
    <>
      <Header />
      <MainRoutes />
      <Footer />
      {Object.entries(modalTypes).map(([key, value]) => <Fragment key={key}>
        <DialogMUI
          open={authModalStatus === key}
          onClose={() => dispatch(setAuthModal(false))}
        >
          <form className={styles.authModal} autoComplete='off'>
            <h4 className={styles.modalTitle}>{value.title}</h4>
            {value.component()}
          </form>
        </DialogMUI>
      </Fragment>
      )}
    </>
  );
};

const modalTypes = {
  signIn: {
    title: 'Sign in',
    component: SignIn
  },
  signUp: {
    title: 'Sign up',
    component: SignUp
  },
  recovery: {
    title: 'Password recovery',
    component: Recovery
  },
  verify: {
    title: 'Verify phone number',
    component: Verify
  },
  recoveryVerify: {
    title: 'Verify phone number',
    component: Verify
  },
  recoveryAfterVerify: {
    title: 'Password recovery',
    component: Recovery
  },
  recoverySuccess: {
    title: 'Password recovery',
    component: Recovery
  },
  deleteAccount: {
    title: 'Delete account',
    component: DeleteAccount
  }
}

export default MainContainer;
