import React, { useEffect, useState } from 'react';
import { ButtonMUI, DialogMUI, MaskInput } from '../../shared';
import styles from './Dashboard.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { customEmptyErrorSnack, customErrorSnack, setAuthModal } from '../../app/appActions';
import { useHistory } from 'react-router-dom';
import { formKeysNames } from '../../helpers/functions';

const Dashboard = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profileInfo } = useSelector(({ app }) => app);
  const [tab, setTab] = useState(0);
  const [sorryModal, setSorryModal] = useState(false);
  const [searchValue, setSearchValue] = useState({
    phone: '',
    upi: ''
  });

  const goToResults = () => {
    if (!!tab) {
      if (searchValue.upi === '+910000000000' || searchValue.upi === '') {
        return dispatch(customEmptyErrorSnack(formKeysNames.upi));
      } else if (!searchValue.upi.includes('@')) {
        return dispatch(customErrorSnack({ error: { response: { data: ['UPI must contains "@"'] } } }));
      }
    } else {
      if (searchValue.phone.includes('_')) {
        return dispatch(customErrorSnack({ error: { response: { data: ['You must write phone number fully'] } } }));
      } else if (searchValue.phone === '+910000000000' || searchValue.phone === '' || searchValue.phone.length < 10) {
        return dispatch(customEmptyErrorSnack(formKeysNames.phone_number));
      }
    }
    history.push(`/search-results/${searchValue[!!tab ? 'upi' : 'phone']}`);
  };

  return (
    <main className={`${styles.dashboard} page-wrap`}>
      <div className={`${styles.dashboardInner} container`}>
        <div className='page-heading main'>
          <h1>
            Welcome to <span>FraudCue</span>
          </h1>
          <p>
            Easily track down scammers and protect your finances with our biggest blacklisted database of fraud phone
            numbers & UPI's in India
          </p>
        </div>

        <section className={styles.searchWrap}>
          <div className={`tabs ${!!tab}`}>
            {tabs.map((el, idx) => (
              <button key={idx} className={`${tab === idx}${tab === idx ? ` active` : ''}`} onClick={() => setTab(idx)}>
                {el.title}
              </button>
            ))}
          </div>
          <label
            className={`${styles.homeInputWrapper} ${styles[!localStorage.getItem('token') ? false : !profileInfo.is_premium]
              }`}
            checks={`${profileInfo.is_premium
                ? ''
                : `${profileInfo.free_scam_request_count === 0 ? 'No' : profileInfo.free_scam_request_count
                } free checks left`
              }`}
          >
            {!!tab ? (
              <input
                className={styles.upiInput}
                placeholder='Enter UPI...'
                value={searchValue.upi}
                onChange={({ target: { value } }) => setSearchValue((prev) => ({ ...prev, upi: value }))}
              />
            ) : (
              <MaskInput
                className={styles.phoneInput}
                getter={searchValue.phone}
                setter={({ target: { value } }) => setSearchValue((prev) => ({ ...prev, phone: value }))}
              />
            )}
            <ButtonMUI
              variant='contained'
              className={styles.searchBtn}
              onClick={() => {
                if (!localStorage.getItem('token')) dispatch(setAuthModal('signIn')); //if not authorized
                else if (!profileInfo.is_premium && profileInfo.free_scam_request_count < 1)
                  setSorryModal(true); //if have not available checks
                else goToResults(); //if have available checks
              }}
            >
              Search
            </ButtonMUI>
          </label>
        </section>
      </div>
      <DialogMUI open={sorryModal} onClose={() => setSorryModal(false)} maxWidth={'xs'}>
        <div className={styles.sorryModal}>
          <p>Sorry</p>
          <span>
            You have run out of free checks.
            <br />
            Pay to get unlimited checks.
          </span>
          <ButtonMUI variant='contained' className={styles.okBtn} onClick={() => setSorryModal(false)}>
            Ok, got it
          </ButtonMUI>
        </div>
      </DialogMUI>
    </main>
  );
};

const tabs = [
  {
    title: 'Phone number'
  },
  {
    title: 'UPI'
  }
];

export default Dashboard;
