import React, {useEffect, useState} from 'react';
import {Link, NavLink, useHistory} from 'react-router-dom';
import {authPath, mainPath} from '../../routes/paths';
import {ReactComponent as Logo} from '../../assets/images/logotype.svg';
import {ReactComponent as PlusIcon} from '../../assets/icons/plus.svg';
import {ReactComponent as DropdownIcon} from '../../assets/icons/dropdown-arrow.svg';
import {ReactComponent as BoxIcon} from '../../assets/icons/box.svg';
import {ReactComponent as LogoutIcon} from '../../assets/icons/logout.svg';
import {ReactComponent as DeleteIcon} from '../../assets/icons/delete.svg';
import styles from './Header.module.scss';
import {ButtonMUI} from '../../shared';
import {useDispatch, useSelector} from 'react-redux';
import {getProfileInfo, setAuthModal} from '../../app/appActions';
import {ClickAwayListener} from '@material-ui/core';
import {classList} from '../../helpers/functions';

const navLinks = [
  {
    path: mainPath.home.path,
    name: mainPath.home.name,
    icon: <PlusIcon className={styles.icon} />
  },
  {
    path: mainPath.chat.path,
    name: mainPath.chat.name,
    icon: <BoxIcon className={styles.icon} />
    //strict: true
  },
  {
    path: 'http://fraudcue.com/home',
    name: 'About Us',
    icon: <BoxIcon className={styles.icon} />
    //strict: true
  }
];

const Header = () => {
  const dispatch = useDispatch();
  const {profileInfo} = useSelector(({app}) => app);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  const logout = () => {
    localStorage.removeItem('token');
    handleClickAway();
    history.push(mainPath.home.path);
  };

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem('token')) dispatch(getProfileInfo());
  }, []);

  return (
    <header className={`${styles.root}${history.location.pathname !== mainPath.home.path ? ` ${styles.notHome}` : ''}`}>
      <div className={`${styles.container}`}>
        <Link className='good-hover my-auto' to={mainPath.home.path}>
          <Logo className={styles.logo} />
        </Link>

        {navLinks?.length > 0 && (
          <nav className={styles.nav}>
            {navLinks?.map(({path, name, strict, icon}) =>
              localStorage.getItem('token') ? (
                name === 'About Us' ? (
                  <button
                    key={name}
                    className={`${styles.navLink}${path === mainPath.home.path ? ' active' : ''}`}
                    onClick={() => window.open('http://fraudcue.com/home', '_self')}
                  >
                    {name}
                  </button>
                ) : (
                  <NavLink key={name} className={styles.navLink} to={path} strict={strict}>
                    {name}
                  </NavLink>
                )
              ) : (
                <button
                  key={name}
                  className={`${styles.navLink}${path === mainPath.home.path ? ' active' : ''}`}
                  onClick={() =>
                    name === 'About Us'
                      ? window.open('http://fraudcue.com/home', '_self')
                      : dispatch(setAuthModal('signIn'))
                  }
                >
                  {name}
                </button>
              )
            )}
          </nav>
        )}

        <div className={`${styles.user}`}>
          {localStorage.getItem('token') ? (
            <>
              <div>
                <span className={styles.userName}>{profileInfo.full_name}</span>
                <span className={[styles.userChecks, styles[profileInfo.is_premium]].join(' ')}>
                  {profileInfo.is_premium
                    ? 'Unlimited checks'
                    : `${
                        profileInfo.free_scam_request_count === 0 ? 'No' : profileInfo.free_scam_request_count
                      } free checks left`}
                </span>
              </div>
              <ClickAwayListener onClickAway={handleClickAway}>
                <div className={classList(styles.dropdown, {[styles.dropdownOpened]: !!open})}>
                  <DropdownIcon onClick={handleClick} className={styles.icon} />
                  {open && (
                    <div className={styles.dropdownContent}>
                      <ButtonMUI variant='text' className={styles.dropdownButton} onClick={logout}>
                        <LogoutIcon /> Log out
                      </ButtonMUI>
                      <div className={styles.dropdownSeparator}></div>
                      <ButtonMUI
                        variant='text'
                        className={classList(styles.dropdownButton, styles.delete)}
                        onClick={() => {
                          handleClickAway();
                          dispatch(setAuthModal('deleteAccount'));
                        }}
                      >
                        <DeleteIcon /> Delete account
                      </ButtonMUI>
                    </div>
                  )}
                </div>
              </ClickAwayListener>
              {/* <LogoutIcon
                onClick={logout}
                className={styles.icon}
              /> */}
            </>
          ) : (
            <>
              <ButtonMUI
                variant='outlined'
                className={[styles.actionBtn, styles.signIn].join(' ')}
                onClick={() => dispatch(setAuthModal('signIn'))}
              >
                Sign in
              </ButtonMUI>
              <ButtonMUI
                variant='contained'
                className={[styles.actionBtn, styles.signUp].join(' ')}
                onClick={() => dispatch(setAuthModal('signUp'))}
              >
                Sign up
              </ButtonMUI>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
