import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { APP } from './appActionTypes';

const INITIAL_STATE = {
  loading: false,
  buttonLoading: false,
  errorSnack: false,
  errorSnackText: '',
  successSnack: '',
  successSnackText: '',
  authModalStatus: false,
  searchStatus: 'loading', // 'loading' || true || false
  sms_token: '',
  password_token: '',
  tempPhone: '',
  profileInfo: {
    full_name: "",
    is_premium: false,
    free_scam_request_count: 0
  },
  chat: {
    next: null,
    previous: null,
    results: []
  }
};

const appReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // APP
    case APP.LOADING:
      return { ...state, loading: action.payload };
    case APP.BUTTON_LOADING:
      return { ...state, buttonLoading: action.payload };
    case APP.ERROR_SNACK_OPEN:
      return { ...state, errorSnack: true, errorSnackText: action.payload };
    case APP.ERROR_SNACK_CLOSE:
      return { ...state, errorSnack: false };
    case APP.SUCCESS_SNACK_OPEN:
      return { ...state, successSnack: true, successSnackText: action.payload };
    case APP.SUCCESS_SNACK_CLOSE:
      return { ...state, successSnack: false };
    // AUTH
    case APP.SET_AUTH_MODAL:
      return { ...state, authModalStatus: action.payload };
    case APP.POST_REGISTRATION_SUCCESS: //registration
      return {
        ...state,
        sms_token: action.payload.data.sms_token,
        tempPhone: action.meta.previousAction.payload.request.data.phone_number,
        authModalStatus: 'verify'
      }
    case APP.POST_REG_VERIFICATION_SUCCESS: //verify registration phone number
      localStorage.setItem('token', action.payload.data.auth_token)
      return {
        ...state,
        sms_token: '',
        tempPhone: '',
        authModalStatus: false
      }
    case APP.RESEND_REG_VER_CODE_SUCCESS:
    case APP.RESEND_RECOVERY_VER_CODE_SUCCESS: //resend verification code
      return {
        ...state,
        sms_token: action.payload.data.sms_token
      }
    case APP.GET_PROFILE_INFO_SUCCESS: //get profile info
      return {
        ...state,
        profileInfo: action.payload.data
      }
    case APP.POST_LOGIN_SUCCESS: //log in
      localStorage.setItem('token', action.payload.data.auth_token)
      return {
        ...state,
        authModalStatus: false
      }
    case APP.DELETE_PROFILE_SUCCESS: //delete account
      localStorage.removeItem('token')
      return {
        ...state,
        authModalStatus: false
      }
    case APP.PASSWORD_RECOVERY_SUCCESS: //password recovery
      return {
        ...state,
        sms_token: action.payload.data.sms_token,
        tempPhone: action.meta.previousAction.payload.request.data.phone_number,
        authModalStatus: 'recoveryVerify'
      }
    case APP.POST_RECOVERY_VERIFICATION_SUCCESS: //password recovery phone verification
      return {
        ...state,
        password_token: action.payload.data.password_token,
        sms_token: '',
        tempPhone: '',
        authModalStatus: 'recoveryAfterVerify'
      }
    case APP.SET_NEW_PASSWORD_SUCCESS: //set new password
      return {
        ...state,
        password_token: '',
        sms_token: '',
        tempPhone: '',
        authModalStatus: 'recoverySuccess'
      }
    // SEARCH RESULTS
    case APP.CHECK_SUCCESS:
      return {
        ...state,
        searchStatus: action.payload.data.success,
        profileInfo: {
          ...state.profileInfo,
          free_scam_request_count: action.payload.data.free_scam_request_count
        }
      };
    case '@@router/LOCATION_CHANGE':
      return { ...state, searchStatus: 'loading' };
    // CHAT
    case APP.GET_CHAT_SUCCESS:
      if (action.meta.previousAction.payload.isPagination) {
        return {
          ...state,
          chat: {
            ...action.payload.data,
            results: [
              ...state.chat.results,
              ...action.payload.data.results
            ]
          }
        }
      } else {
        return {
          ...state,
          chat: action.payload.data
        }
      }
    default:
      return state;
  }
};

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
  });

export default rootReducer;
