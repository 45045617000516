import { useState } from 'react'
import { ReactComponent as OpenEye } from '../../assets/icons/eye.svg';
import { ReactComponent as CloseEye } from '../../assets/icons/eye-crossed.svg';
import styles from './Auth.module.scss'
import { useDispatch } from 'react-redux';
import { setAuthModal } from '../../app/appActions';

export const FraudFieldLabel = ({ title, children }) => {
    return <label className={styles.fraud_field_label}>
        <span className={styles.fraud_field_label__title}>{title}</span>
        <div className={styles.fraud_field_label__content}>{children}</div>
    </label>
}


export const PasswordField = ({ getter, setter, title, placeholder, withRecoverBtn }) => {
    const dispatch = useDispatch();
    const [isVisibleValue, setVisibleValue] = useState(false);

    return <FraudFieldLabel
        title={title}
    >
        <div className={styles.fraud_field_password}>
            <input
                type={isVisibleValue ? 'text' : 'password'}
                autoComplete={`new-password`}
                placeholder={placeholder}
                value={getter}
                onChange={setter}
            />
            {isVisibleValue
                ? <CloseEye
                    onClick={() => setVisibleValue(false)}
                />
                : <OpenEye
                    onClick={() => setVisibleValue(true)}
                />
            }
            {withRecoverBtn && <button type='button' className={['fraud-text', styles.recoverBtn].join(' ')} onClick={() => dispatch(setAuthModal('recovery'))}>Forgot password?</button>}
        </div>
    </FraudFieldLabel>
}

