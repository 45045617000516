import React from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { mainPath } from './paths';
import Dashboard from '../features/Dashboard';
import NotFound from '../shared/NotFound';
import Chat from '../features/Chat';
import ResultsPage from '../features/ResultsPage';

const MainRoutes = () => {
  const history = useHistory()
  if (!localStorage.getItem('token') && history.location.pathname !== mainPath.home.path) return <Redirect to={mainPath.home.path} />;
  if (history.location.pathname === '/search-results') return <Redirect to={mainPath.home.path} />;

  return (
    <Switch>
      <Route path={mainPath.home.path} component={Dashboard} />
      <Route path={`${mainPath.searchResults.path}/:searchValue`} component={ResultsPage} />
      <Route path={mainPath.chat.path} component={Chat} />
      <Route path='*' component={NotFound} />
    </Switch>
  );
};

export default MainRoutes;
