import React, { useEffect, useState } from 'react';
import { FraudFieldLabel, PasswordField } from './helperAuthFunc';
import { ButtonMUI, MaskInput } from '../../shared';
import styles from './Auth.module.scss'
import { useDispatch, useSelector } from 'react-redux';
import { customEmptyErrorSnack, customErrorSnack, passwordRecovery, setAuthModal, setNewPassword } from '../../app/appActions';
import { formKeysNames } from '../../helpers/functions';

const initialRecoveryValues = {
    phone_number: ''
}

const initialRecoveryAfterVerifyValues = {
    password_1: '',
    password_2: ''
}

const Recovery = () => {
    const dispatch = useDispatch();
    const { authModalStatus, password_token } = useSelector(({ app }) => app)
    const [recoveryValues, setRecoveryValues] = useState(initialRecoveryValues)
    const [recoveryAfterVerifyValues, setRecoveryAfterVerifyValues] = useState(initialRecoveryAfterVerifyValues)
    const [btnLoad, setBtnLoad] = useState(false)

    useEffect(() => {
        setRecoveryValues(initialRecoveryValues)
        setRecoveryAfterVerifyValues(initialRecoveryAfterVerifyValues)
    }, [authModalStatus])

    const recoveryConfirm = () => {
        if (authModalStatus === 'recoverySuccess') {
            dispatch(setAuthModal('signIn'))
        } else if (authModalStatus === 'recoveryAfterVerify') {
            if (Object.values(recoveryAfterVerifyValues).some(el => el === '+910000000000' || el === '')) {
                const [emptyKey] = Object.entries(recoveryAfterVerifyValues).find(([key, value]) => value === '+910000000000' || value === '')
                dispatch(customEmptyErrorSnack(formKeysNames[emptyKey]))
            } else if (recoveryAfterVerifyValues.password_1 !== recoveryAfterVerifyValues.password_2) {
                dispatch(customErrorSnack({ error: { response: { data: ['Both passwords must be same'] } } }))
            } else {
                setBtnLoad(true)
                dispatch(setNewPassword(recoveryAfterVerifyValues, password_token)).then((res) => {
                    if (res.type.includes('_FAIL')) dispatch(customErrorSnack(res))
                    setBtnLoad(false)
                })
            }
        } else if (authModalStatus === 'recovery') {
            if (Object.values(recoveryValues).some(el => el === '+910000000000' || el === '')) {
                const [emptyKey] = Object.entries(recoveryValues).find(([key, value]) => value === '+910000000000' || value === '')
                dispatch(customEmptyErrorSnack(formKeysNames[emptyKey]))
            } else {
                setBtnLoad(true)
                dispatch(passwordRecovery(recoveryValues)).then((res) => {
                    if (res.type.includes('_FAIL')) dispatch(customErrorSnack(res))
                    setBtnLoad(false)
                })
            }
        }
    }

    const renderRecoveryState = () => {
        switch (authModalStatus) {
            case 'recovery':
                return <>
                    <p className={styles.desc}>
                        Enter your phone number and we’ll sent you an SMS<br />code to reset your password
                    </p>
                    <FraudFieldLabel
                        title={'Phone number'}
                    >
                        <MaskInput
                            className={styles.phoneInput}
                            getter={recoveryValues.phone_number}
                            setter={({ target: { value } }) => setRecoveryValues(prev => ({ ...prev, phone_number: value }))}
                        />
                    </FraudFieldLabel>
                </>
            case 'recoveryAfterVerify':
                return <>
                    <p className={styles.desc}>
                        Create a new password and confirm it
                    </p>
                    <PasswordField
                        title={'New password'}
                        placeholder={'Enter your new password...'}
                        getter={recoveryAfterVerifyValues.password_1}
                        setter={({ target: { value } }) => setRecoveryAfterVerifyValues(prev => ({ ...prev, password_1: value }))}
                    />

                    <PasswordField
                        title={'Confirm password'}
                        placeholder={'Repeat your new password...'}
                        getter={recoveryAfterVerifyValues.password_2}
                        setter={({ target: { value } }) => setRecoveryAfterVerifyValues(prev => ({ ...prev, password_2: value }))}
                    />
                </>
            case 'recoverySuccess':
                return <>
                    <p className={styles.desc}>
                        Success! Your password has been changed.<br />
                        Now you can log in.
                    </p>
                </>
            default:
                return null
        }
    }

    return <div className={styles.modalWrapper}>
        {renderRecoveryState()}
        <ButtonMUI
            variant='contained'
            className={styles.submitBtn}
            onClick={recoveryConfirm}
            disabled={btnLoad}
        >
            {authModalStatus === 'recovery'
                ? 'Continue'
                : authModalStatus === 'recoveryAfterVerify'
                    ? 'Confirm'
                    : authModalStatus === 'recoverySuccess'
                        ? 'Sign in'
                        : ''
            }
        </ButtonMUI>
        {authModalStatus === 'recovery' && <p className={styles.info}><button type='button' onClick={() => dispatch(setAuthModal('signIn'))} className='fraud-text'>Back to Sign in</button></p>}
    </div>
};

export default Recovery