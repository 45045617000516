import React from 'react';
import InputMask from 'react-input-mask';
import { ReactComponent as Flag } from '../../assets/icons/flag.svg';
import styles from './MaskInput.module.scss';

const MaskInput = ({ getter, setter, className }) => (
  <div className={styles.root}>
    <Flag />
    <span>+91</span>
    <InputMask
      className={className}
      placeholder='00 0000 0000'
      mask='99 9999 9999'
      //maskChar="0"
      value={getter.startsWith('+91') || getter.startsWith('91') ? getter.slice(3) : getter}
      onChange={(e) => setter({ ...e, target: { ...e.target, value: `+91${e.target.value.replace(/ /g, '')}` } })}
      name='fraud_phone'
      autoComplete='fraud_phone'
      onPaste={(e) => {
        e.preventDefault();
        let clipboardData = e.clipboardData.getData('text/plain').toString();
        clipboardData = clipboardData.replace(/ /g, '');
        if (clipboardData.length > 10 && (clipboardData.startsWith('+91') || clipboardData.startsWith('91'))) {
          if (!clipboardData.includes('+')) clipboardData = '+' + clipboardData;
          setter({ ...e, target: { ...e.target, value: clipboardData } });
        } else {
          setter({ ...e, target: { ...e.target, value: `+91${clipboardData}` } });
        }
      }}
    />
  </div>
);

export default MaskInput;
