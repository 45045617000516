import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const joinQueries = (arr) => `${arr.length && arr.length !== 0 ? '?' : ''}${arr.join('&')}`;

export function getOption(label) {
  return (
    <div className={`status ${label}`}>
      <div>
        {label !== 'All networks' && <span />}
        {label}
      </div>
    </div>
  );
}

export function splitByCommas(data) {
  return data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// Scroll Restoration
export function ScrollToTop() {
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

// Convert string to slug
export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  let from = 'åàáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  let to = 'aaaaaaeeeeiiiioooouuuunc------';

  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-') // collapse dashes
    .replace(/^-+/, '') // trim - from start of text
    .replace(/-+$/, ''); // trim - from end of text

  return str;
}

export const generateId = () => Math.floor(Math.random() * Date.now());

export const randomInteger = (max) => Math.floor(Math.random() * (max + 1));

export const randomRgbColor = () => {
  let r = randomInteger(255);
  let g = randomInteger(255);
  let b = randomInteger(255);
  return `rgb(${r}, ${g}, ${b})`;
};

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export const dateForQuery = (date) => encodeURIComponent(date?.toISOString().replace('.000Z', ''));

export const formatNumber = (value) =>
  Number(value)
    .toLocaleString('fr', {
      maximumFractionDigits: 2
    })
    .replace(',', '.');

export const isNegative = (num) => Math.sign(num) === -1;

export const capitalize = (string) => string && string.charAt(0).toUpperCase() + string.slice(1);

export const formKeysNames = {
  full_name: 'Full name',
  phone_number: 'Phone',
  //email: 'Email',
  city: 'City',
  password: 'Password',
  password_1: 'New password',
  password_2: 'Confirm password',
  upi: 'UPI'
};

export const classList = (...classes) => classes.map(el => typeof el === 'object'
  ? Object.entries(el).filter(([_, value]) => !!value).map(([key, _]) => key).join(' ')
  : el)
  .filter((el) => !!el).join(' ')
