import React, { useEffect, useState } from 'react';
import styles from './ResultsPage.module.scss';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as Logo2 } from '../../assets/icons/logo2.svg';
import { ReactComponent as SuccessIcon } from '../../assets/icons/success_icon.svg';
import { ReactComponent as FailIcon } from '../../assets/icons/fail_icon.svg';
import { checkAction } from '../../app/appActions';
import { ButtonMUI } from '../../shared';
import { mainPath } from '../../routes/paths';

const ResultsPage = () => {
  const dispatch = useDispatch()
  const { searchValue } = useParams()
  const [isPhonePage, setPhonePage] = useState(searchValue.startsWith('+'))
  const { searchStatus } = useSelector(({ app }) => app)

  useEffect(() => {
    window.history.replaceState(null, "FraudCue", "/search-results")
    dispatch(checkAction(isPhonePage, { [isPhonePage ? 'phone_number' : 'upi']: isPhonePage ? searchValue.slice(3) : searchValue })).then(res => {
      if (res.payload.data.success) {
        if (res.payload.data.hasOwnProperty('is_phone_number')) setPhonePage(res.payload.data.is_phone_number)
      }
    })
  }, [searchValue])

  const searchTitle = () => {
    if (searchValue.startsWith('+')) {
      let tempVal = searchValue.split('');
      tempVal.splice(3, 0, ' ')
      tempVal.splice(6, 0, ' ')
      tempVal.splice(11, 0, ' ')
      return tempVal
    }
    return searchValue
  }

  return (
    <main className={`${styles.chat} page-wrap`}>
      <div className={`${styles.chatInner} container`}>
        <div className="page-heading">
          <span>Search results for</span>
          <h1>{searchTitle()}</h1>
        </div>
        <section className={styles.resultsWrap}>
          {searchStatus === 'loading'
            ? <FraudLoader />
            : <>
              <div className={`${styles.resultsWrap__table} ${styles[!searchStatus]}`}>
                {!searchStatus ? <SuccessIcon /> : <FailIcon />}
                <p>{!searchStatus
                  ? `Our database does not contain any information regarding this ${isPhonePage ? 'number' : 'UPI'}`
                  : `We found records for this ${isPhonePage ? 'number' : 'UPI'} in our database and recognized it as a scam`
                }</p>
              </div>
              <div className={styles.separator} />
              <p className={styles.infoText}>{!searchStatus
                ? <>If you believe this is a scam, share your experience by leaving a comment<br />in the chat to help others avoid it</>
                : <>If you had a bad experience with this scammer, please leave a comment<br />in the chat to help others avoid it</>
              }</p>
              <ButtonMUI
                variant='contained'
                className={styles.searchBtn}
                href={mainPath.chat.path}
              >
                Go to chat
              </ButtonMUI>
              <ButtonMUI
                variant='text'
                className={styles.searchBtn}
                href={mainPath.home.path}
              >
                Go back
              </ButtonMUI>
            </>
          }
        </section>
      </div>
    </main>
  );
};

const FraudLoader = ({ width = 128, height = 128 }) => <div
  className={styles.fraudLoader}
  style={{
    width: width,
    height: height
  }}
>
  <Logo />
  <Logo2 className={styles.rotating} />
</div>

export default ResultsPage;
